export default [{
        currencyId: 1,
        code: "SA",
        country: ""
    }, {
        currencyId: 2,
        code: "",
        country: "Australia"
    }, {
        currencyId: 5,
        code: "",
        country: "United Kingdom"
    }, {
        currencyId: 6,
        code: "HK",
        country: "Hong Kong"
    },
    {
        currencyId: 7,
        code: "ID",
        country: "Indonesia"
    }, {
        currencyId: 8,
        code: "",
        country: "India"
    },
    {
        currencyId: 9,
        code: "JP",
        country: "Japan"
    },
    {
        currencyId: 10,
        code: "KR",
        country: "South Korea"
    },
    {
        currencyId: 11,
        code: "HK",
        country: "Hong Kong"
    },
    {
        currencyId: 12,
        code: "MY",
        country: "Malaysia"
    },
    {
        currencyId: 13,
        code: "TW",
        country: "Taiwan"
    },
    {
        currencyId: 14,
        code: "SA",
        country: "Saudi Arabia"
    },
    {
        currencyId: 15,
        code: "SG",
        country: "Singapore"
    },
    {
        currencyId: 16,
        code: "TH",
        country: "Thailand"
    },
    {
        currencyId: 17,
        code: "TR",
        country: "Turkey"
    }, {
        currencyId: 18,
        code: "",
        country: "United States"
    },
    {
        currencyId: 19,
        code: "VN",
        country: "Vietnam"
    },
    {
        currencyId: 20,
        code: "PH",
        country: "Philippines"
    },
    {
        currencyId: 21,
        code: "ID",
        country: "Indonesia"
    }, {
        currencyId: 22,
        code: "",
        country: "Brazil"
    },
    {
        currencyId: 25,
        code: "SA",
        country: "Saudi Arabia"
    }, {
        currencyId: 26,
        code: "",
        country: "Mexico"
    },
]