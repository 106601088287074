<template>
  <div class="left-side">
    <div class="search" v-if="showSearch">
      <el-input
        placeholder="请输入内容"
        class="search-input"
        size="small"
        v-model="keyword"
        clearable
      >
        <template slot="append">
          <el-button type="danger" @click="$emit('search', { keyword })">搜索</el-button>
        </template>
      </el-input>
    </div>
    <p class="add-link" v-if="addItemText" :style="{ color: color }" @click="$emit('add')">
      +{{ addItemText }}
    </p>
    <ul class="widget-list" v-if="list.length">
      <li
        v-for="(item, index) in list"
        :key="index"
        @click="changeCurrent(index,item)"
        ref="items"
        :style="{ color: current === index ? color : '#333' }"
        :class="[scrollPosition == 'right' ? 'border-right' : 'border-left']"
      >
        <i v-if="item.icon" :class="item.icon" />
        <p>{{ item[labelKey] }}</p>
      </li>
      <li
        :style="{ top: leftBar + 'px', background: color, height: height + 'px' }"
        class="li-background"
      ></li>
      <li
        class="scroll-bar"
        :style="{ top: leftBar + 'px', background: color, height: height + 'px' }"
        :class="[ scrollPosition == 'right' ? 'position-right' : 'position-left' ]"
      ></li>
    </ul>
    <div v-else>
      <div class="loading">
        {{showLoading?'加载中...':'暂无数据'}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showSearch: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => [],
    },
    scrollPosition: {
      type: String,
      default: "right",
    },
    color: {
      type: String,
      default: "#409EFF",
    },
    addItemText: {
      type: String,
      default: "",
    },
    showLoading:{
      type:Boolean,
      default:false
    },
    labelKey:{
      type:String,
      default:'name'
    }
  },
  data() {
    return {
      leftBar: 0,
      current: -1,
      keyword: "",
      height:0
    };
  },
  methods: {
    changeCurrent(index,item) {
      this.current = index;
      if(item){
        this.$emit("change",item);
      }
      if(index > -1){
        this.$refs["items"][index].scrollIntoView({behavior: "smooth"});
      }
      this.$nextTick(() => {
        const activeSide = this.$refs["items"]
          ? this.$refs["items"][this.current]
          : null;
        this.height = activeSide?.offsetHeight ?? 0;
        this.leftBar = activeSide?.offsetTop ?? -100;
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      const activeSide = this.$refs["items"]
        ? this.$refs["items"][this.current]
        : null;
      this.leftBar = activeSide?.offsetTop ?? -100;
      this.changeCurrent(0,this.list[0])
    });
  },
};
</script>
<style lang="scss">
.search .el-input-group__append {
  background: #409eff;
  border-color:#409eff;
  color: #fff;
}
::-webkit-scrollbar{
	background-color:  #fff;
	width: 8px;
	height: 8px;
}
::-webkit-scrollbar-thumb{
	background-color: rgba(0,0,0,.2);
	border-radius: 6px;
}
</style>
<style lang="scss" scoped>
.left-side {
  background-color: #fff;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .search {
    padding: 10px;
  }
  .add-link{
    font-size: 14px;
    padding: 0 10px 10px;
    cursor: pointer;
  }
  .loading{
    line-height: 56px;
    font-size: 16px;
    color: #778;
    text-align: center;
  }
  .widget-list {
    width: 100%;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1;
    li {
      cursor: pointer;
      border-color: rgba(0, 0, 0, 0);
      position: relative;
      list-style: none;
      // height: 42px;
      line-height: 42px;
      &.border-left {
        border-left-width: 4px;
      }
      &.border-right {
        border-right-width: 4px;
      }
      &:hover {
        background: rgba(64,158,255,.2);
      }
      i {
        font-size: 24px;
      }
      p {
        font-size: 14px;
        padding-left: 20px;
      }
    }
    .li-background {
        position: absolute;
        width: 100%;
        left: 0;
        z-index: 0;
        opacity: 0.2;
        transition: all 0.3s ease;
    }
    .scroll-bar {
      position: absolute;
      width: 4px;
      bottom: auto;
      transition: all 0.3s ease;
      margin-top: 0;
      &.position-right {
        left: auto;
        right: 0;
      }
      &.position-left {
        left: 0;
        right: auto;
      }
    }
  }
}
</style>
