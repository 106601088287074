<template>
  <el-table
    ref="multipleTable"
    :data="tableData"
    tooltip-effect="dark"
    style="width: 100%"
    height="500"
    v-loading="loading"
  >
    <el-table-column label="关键词" prop="title" width="200"></el-table-column>
    <el-table-column label="翻译" prop="cnTitle" width="150"></el-table-column>
    <el-table-column prop="pubDate" label="日期">
      <template v-slot="{ row }">
        <span>{{ formatDate(row.pubDate) }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="approx_traffic" label="搜索次数"> </el-table-column>
    <el-table-column prop="orderNum" label="FB兴趣词">
      <template v-slot="{ row }">
        <el-link
          :underline="false"
          @click.stop="tranlateRow(row, $event)"
          type="primary"
        >
          点击查看
        </el-link>
      </template>
    </el-table-column>
    <el-table-column prop="roi" label="新闻链接">
      <template v-slot="{ row }">
        <el-link :href="getLink(row)" target="_blank" :underline="false" type="primary">点击访问</el-link>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    tranlateRow(row, e) {
      e.stopPropagation();
      this.$emit("view-targeting", row);
    },
    formatDate(time) {
      let date = new Date(time);
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    getLink(row) {
      if(row.news_item && row.news_item.length){
        return row.news_item[0].news_item_url
      }
      return row.link;
    }
  },
};
</script>