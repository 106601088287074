<template>
  <span class="recommend-content">
    <el-dropdown trigger="click" v-if="list" @command="handleCommand">
      <el-button type="primary" size="medium" :disabled="!productList.length">
        点我获取受众词推荐<i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item disabled>选择商品</el-dropdown-item>
        <el-dropdown-item
          v-for="item in productList"
          :key="item.saleId"
          :command="item"
          >{{ item.productName }}</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
    <el-button
      type="primary"
      icon="el-icon-thumb"
      @click="openGetRecommend"
      size="medium"
      v-else
    >
      点我获取受众词推荐
    </el-button>
    <el-dialog
      title="受众词推荐"
      :visible.sync="dialogVisible"
      width="70%"
      :modal-append-to-body="false"
      :append-to-body="true"
      custom-class="recomment-dialog"
    >
      <el-container>
        <el-aside width="120px">
          <side-bar
            :list="months"
            label-key="label"
            @change="selectMonth"
            ref="sidebar"
          ></side-bar>
        </el-aside>
        <el-main>
          <div class="flex-btw">
            <h3>{{ category }}</h3>
            <el-select
              v-show="currentMonth.month == 'hotWords'"
              v-model="subCategory"
              @change="changeCate"
              clearable=""
              placeholder="请选择分类"
            >
              <el-option
                v-for="item in categoryList"
                :key="item.id"
                :label="item.name"
                :value="item.category"
              >
              </el-option>
            </el-select>
          </div>
          <audience-table
            @sort-change="handleSortChange"
            :loading="showLoading"
            @multiple="multipleTable"
            ref="audienceTable"
            :table-data="tableData"
            @translate-row="tranlateRow"
            v-show="!showNEWSHot && !showHotWords"
          ></audience-table>
          <hot-word-table
            v-show="showHotWords"
            :table-data="hotWordsList"
            @view-targeting="viewHotTargeting"
            :loading="showLoading"
          ></hot-word-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNumber"
            :page-sizes="[10, 20, 50, 100, 200]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            v-show="!showNEWSHot"
          >
          </el-pagination>
          <news-hot
            v-show="showNEWSHot"
            :table-data="hotList"
            :loading="showLoading"
            @view-targeting="viewHotTargeting"
          ></news-hot>
          <div class="tags">
            <span>已选受众词：</span>
            <div>
              <el-tag
                :key="tag.targeting_code"
                v-for="tag in selectedTags"
                closable
                :disable-transitions="false"
                @close="handleClose(tag)"
                class="tag-item"
              >
                {{ tag.targeting }}
              </el-tag>
            </div>
          </div>
        </el-main>
      </el-container>
      <div slot="footer">
        <div>
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addKeywords"> 添 加 </el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="FB兴趣词:关键词"
      :visible.sync="showHost"
      width="50%"
      append-to-body
      custom-class="recomment-dialog"
    >
      <fb-hot :table-data="fbHotList" @multiple="multipleHot"></fb-hot>
      <div slot="footer">
        <div>
          <el-button @click="showHost = false">取 消</el-button>
          <el-button type="primary" @click="addHotKeywords"> 添 加 </el-button>
        </div>
      </div>
    </el-dialog>
  </span>
</template>
<script>
/**
	 * recommendAudiences 受众推荐
	 * @description 受众推荐，用于创建广告，编辑广告组，批量复制广告组和广告系列。
	 * @property {Array} list 广告组或广告系列列表，有此属性其它属性不生效[{id:'',level:'adset/campaigns'}]
	 * @property {Object} saleInfo 商品信息，有此属性，adsetId不生效
	 * @property {String} adsetId 广告组id
	 * @event {Function} confirm 确定添加，返回符合受众推荐的列表
	 * @example <recommend-audience :list="list" @confirm="confirmAdd"></recommend-audience>
	 */
import sideBar from "./sidebar";
import audienceTable from "./audienceTable";
import newsHot from "./newsHot";
import fbHot from "./fbHot";
import searchCode from "./searchCode";
import hotWordTable from "./hotWordTabel";
import category from "./category";
import {
  audiencesDetail,
  targetingSearch,
  findSaleByAdSetId,
  translate,
  googleTrends,
  getHotWords,
  findSaleByCampaignIds,
} from "@/api/audiences";
import { mapGetters } from "vuex";
export default {
  components: {
    sideBar,
    audienceTable,
    newsHot,
    fbHot,
    hotWordTable,
  },
  props: ["saleInfo", "adsetId", "list",'targetUserId'],
  data() {
    return {
      dialogVisible: false,
      months: [],
      showLoading: false,
      pageNumber: 1,
      pageSize: 20,
      total: 0,
      requestMonth: [],
      sortKey: "useNum",
      sortType: "desc",
      category: "",
      selectMap: new Map(),
      currentMonth: {},
      selectKeywords: [],
      selectedTags: [],
      tableData: [],
      isChange: false,
      timer: null,
      onTranslate: false,
      salInfo: {},
      showNEWSHot: false,
      searchCode,
      hotList: [],
      fbHotList: [],
      selectedHotList: [],
      showHost: false,
      showHotWords: false,
      hotWordsList: [],
      categoryList: category,
      subCategory: "",
      productList: [],
    };
  },
  computed: {
    ...mapGetters(["accountId"]),
    dropdownList() {
      return this.groupsIds
        ? this.groupsIds.replace(/vi\-/g, "").split(",")
        : [];
    },
  },
  methods: {
    // 选择月份
    selectMonth(e) {
      this.currentMonth = e;
      this.showNEWSHot = false;
      this.showHotWords = false;
      this.pageNumber = 1;
      if (e.month == "newHot") {
        this.getNewsHot();
        return;
      } else if (e.month == "hotWords") {
        this.getHotWords();
        return;
      }
      this.requestMonth = e.month;
      this.isChange = true;
      this.getList();
    },
    // 获取左侧月份列表
    getMonths() {
      const date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth();
      let currentMonth = {
        label: "本月",
        month: [`${year}${month + 1 > 9 ? month + 1 : "0" + (month + 1)}`],
      };
      this.months.push(currentMonth);
      let recentTwoMonths = {
        label: "最近两月",
        month: [],
      };
      let currentSeason = {
        label: "本季度",
        month: [],
      };
      let lastYearSeason = {
        label: "去年本季度",
        month: [],
      };
      let currentYear = {
        label: "本年",
        month: [],
      };
      recentTwoMonths.month.push(
        `${year}${month + 1 > 9 ? month + 1 : "0" + (month + 1)}`
      );
      const season = parseInt(month / 3);
      for (let i = 1; i <= 3; i++) {
        currentSeason.month.push(
          `${year}${
            season * 3 + i > 9 ? season * 3 + i : "0" + (season * 3 + i)
          }`
        );
        lastYearSeason.month.push(
          `${year - 1}${
            season * 3 + i > 9 ? season * 3 + i : "0" + (season * 3 + i)
          }`
        );
      }
      for (let i = 1; i <= month + 1; i++) {
        currentYear.month.push(`${year}${i < 10 ? "0" + i : i}`);
      }
      date.setMonth(month - 1);
      month = date.getMonth();
      year = date.getFullYear();
      let lastMonth = {
        label: "上月",
        month: [`${year}${month + 1 > 9 ? month + 1 : "0" + (month + 1)}`],
      };
      recentTwoMonths.month.unshift(
        `${year}${month + 1 > 9 ? month + 1 : "0" + (month + 1)}`
      );
      this.months.push(lastMonth);
      this.months.push(recentTwoMonths);
      this.months.push(currentSeason);
      this.months.push(currentYear);
      this.months.push(lastYearSeason);
      this.months.push({ label: "新闻热点词", month: "newHot" });
      this.months.push({ label: "热搜词", month: "hotWords" });
    },
    // 修改pagesize
    handleSizeChange(e) {
      this.pageNumber = 1;
      this.pageSize = e;
      if (this.currentMonth.month == "hotWords") {
        this.getHotWords();
      } else {
        this.getList();
      }
    },
    // 翻页
    handleCurrentChange(e) {
      this.pageNumber = e;
      if (this.currentMonth.month == "hotWords") {
        this.getHotWords();
      } else {
        this.getList();
      }
    },
    // 排序
    handleSortChange(e) {
      this.sortKey = e.prop;
      this.sortType = e.order == "descending" ? "desc" : "asc";
      this.pageNumber = 1;
      this.getList();
    },
    // 删除关键词
    handleClose(tag) {
      let item, target;
      for (const [key, value] of this.selectMap) {
        let index = value.findIndex((val) => val.targeting == tag.targeting);
        if (index > -1) {
          item = value.splice(index, 1);
          target = key;
        }
        this.selectMap.set(key, value);
      }
      this.selectedTags = this.selectedTags.filter(
        (v) => v.targeting != tag.targeting
      );
      if (target == this.currentMonth) {
        this.$refs.audienceTable.closeSelection(item[0]);
      }
    },
    // 选择关键词
    multipleTable(e) {
      this.timer && clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        let list = this.selectMap.get(this.currentMonth)
          ? JSON.parse(JSON.stringify(this.selectMap.get(this.currentMonth)))
          : [];
        list = list.filter((v) => {
          return (
            this.tableData.findIndex((item) => item.targeting == v.targeting) ==
            -1
          );
        });
        for (const [key, value] of this.selectMap) {
          e.map((v) => {
            let index = value.findIndex((val) => val.targeting == v.targeting);
            if (index > -1) {
              value.splice(index, 1);
            }
          });
          this.selectMap.set(key, value);
        }
        this.selectMap.set(this.currentMonth, [...list, ...e]);
        this.selectedTags = [];
        for (const [key, value] of this.selectMap) {
          value.map((v) => {
            this.selectedTags.push(v);
          });
        }
      }, 100);
    },
    handleCommand(e) {
      console.log(e);
      this.salInfo = e;
      this.$nextTick(() => {
        this.dialogVisible = true;
      });
    },
    // 打开推荐词弹框
    openGetRecommend() {
      if (!this.saleInfo) {
        if (this.adsetId) {
          this.findSaleByAdSetId(this.adsetId);
        }
      } else {
        this.salInfo = this.saleInfo;
        this.dialogVisible = true;
      }
    },
    // 根据广告组id获取对应商品详情
    findSaleByAdSetId(adsetId) {
      findSaleByAdSetId({ adsetId }).then((res) => {
        if (res.code == 0) {
          if (res.data instanceof Array) {
            if (res.data.length) {
              this.salInfo = res.data[0];
              this.$nextTick(() => {
                this.dialogVisible = true;
              });
            } else {
              this.$message.error("该广告组没有关联商品，暂无推荐受众");
            }
          } else {
            if (res.data) {
              this.salInfo = res.data;
              this.$nextTick(() => {
                this.dialogVisible = true;
              });
            } else {
              this.$message.error("该广告组没有关联商品，暂无推荐受众");
            }
          }
        } else {
          this.$message.error("该广告组没有关联商品，暂无推荐受众");
        }
      });
    },
    // 获取推荐列表
    getList() {
      const {
        pageNumber,
        pageSize,
        requestMonth,
        salInfo,
        sortKey,
        sortType,
      } = this;
      if (!requestMonth) return;
      const data = {
        pageNumber,
        pageSize,
        month: requestMonth,
        saleId: salInfo.saleId,
        sortKey,
        sortType,
      };
      // data.saleId = "103362340";
      this.showLoading = true;
      audiencesDetail(data)
        .then((res) => {
          this.showLoading = false;
          if (res.code != 0) {
            this.$message.error(res.comment);
            this.category = "";
            this.tableData = [];
            this.total = 0;
          } else {
            const list = this.changeTabelData(res.data.audiences);
            this.category = res.data.category;
            this.tableData = res.data.audiences;
            this.tableData.map((v) => {
              v.translation = "";
            });
            this.total = res.data.total;
            this.$refs.audienceTable.toggleSelection(list);
            // this.changeTabelData();
          }
        })
        .catch((err) => {
          console.log(err);
          this.showLoading = false;
        });
    },
    // 当tableData改变时，修改选中
    changeTabelData(data) {
      const list = data.filter((v) => {
        let flag = false;
        for (const [key, value] of this.selectMap) {
          if (value.find((item) => item.targeting == v.targeting)) {
            flag = true;
          }
        }
        return flag;
      });
      return list;
    },
    // 确认添加
    addKeywords() {
      const list = [];
      const selectList = [];
      for (const [key, value] of this.selectMap) {
        value.map((v) => {
          selectList.push(v);
          list.push(v.targeting_code);
        });
      }
      if (!list.length) {
        this.$message.error("请选择推荐词");
        return;
      }
      const params = {
        accountId: this.accountId,
        targetingType: "interests",
        query: list.join() + ",",
      };
      this.$showLoading();
      targetingSearch(params)
        .then((res) => {
          this.$hideLoading();
          this.clearSelect();
          if (res.data && res.data.length) {
            res.data.map((v) => {
              selectList.map((s) => {
                if (v.id == s.targeting_code) {
                  v.name = s.targeting;
                }
              });
            });
            this.$emit("confirm", res.data);
            this.dialogVisible = false;
          } else {
            this.$message.warning("本次操作没有命中关键词。。。");
          }
        })
        .catch((err) => {
          console.log(err);
          this.$hideLoading();
          this.$message.error("添加失败");
          this.clearSelect();
        });
    },
    clearSelect() {
      this.selectMap = new Map();
      this.selectedTags = [];
      this.$refs.audienceTable.clearSelection();
    },
    // 翻译关键词
    tranlateRow(row) {
      if (this.onTranslate) {
        this.$message.info("正在翻译");
        return;
      }
      let content = row.targeting;
      const data = {
        languageId: 1,
        api: 1,
        content,
      };
      this.onTranslate = true;
      translate(data)
        .then((res) => {
          // console.log(res);
          this.onTranslate = false;
          if (res.code == 0) {
            row.translation = res.data.target;
            let index = this.tableData.findIndex(
              (v) => v.targeting_code == row.targeting_code
            );
            this.$set(this.tableData, index, row);
          } else {
            this.$message.error("翻译失败");
          }
        })
        .catch((err) => {
          console.log(err);
          this.onTranslate = false;
        });
    },
    // 获取新闻关键词
    getNewsHot() {
      this.showNEWSHot = true;
      let code = "";
      this.searchCode.map((v) => {
        if (v.currencyId == this.salInfo.currencyId) {
          code = v.code;
        }
      });
      if (!code) {
        this.$message.error("没有该商品所在地区的新闻推荐");
        return;
      }
      this.showLoading = true;
      googleTrends(code)
        .then((res) => {
          this.showLoading = false;
          if (res.data && res.data.item) {
            this.hotList = res.data.item;
            let content = res.data.item.map((v) => v.title).join("\n");
            translate({
              languageId: 1,
              api: 2,
              content,
            }).then((resp) => {
              let list = resp.data.target.split("\n");
              this.hotList = this.hotList.map((v, i) => {
                v.cnTitle = list[i];
                return v;
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 新闻热词获取推荐词
    viewHotTargeting(row) {
      const params = {
        accountId: this.accountId,
        targetingType: "interests",
        query: row.title,
      };
      targetingSearch(params).then((res) => {
        console.log(res);
        if (!res.data || !res.data.length) {
          this.$message.error("没有相关FB兴趣词~~");
          return;
        }
        this.fbHotList = res.data;
        this.showHost = true;
      });
    },
    multipleHot(e) {
      this.selectedHotList = e.map((v) => {
        return {
          targeting: v.name,
          targeting_code: v.id,
        };
      });
    },
    addHotKeywords() {
      this.showHost = false;
      this.multipleTable(this.selectedHotList);
    },
    getHotWords() {
      this.showHotWords = true;
      let country = "";
      this.searchCode.map((v) => {
        if (v.currencyId == this.salInfo.currencyId) {
          country = v.country;
        }
      });
      if (!country) {
        this.$message.error("没有该商品所在国家的热搜词");
        return;
      }
      this.showLoading = true;
      const params = {
        t: new Date().getTime(),
        country,
        page: this.pageNumber,
        size: this.pageSize,
        dataType: 2,
      };
      if (this.subCategory) {
        params.subCategory = this.subCategory;
      }
      getHotWords(params)
        .then((res) => {
          this.showLoading = false;
          this.total = res.page?.total ?? 0;
          this.hotWordsList = res.data;
          if (res.data && res.data.length > 0) {
            let content = res.data.map((v) => v.query).join("\n");
            translate({
              languageId: 1,
              api: 2,
              content,
            }).then((resp) => {
              let list = resp.data.target.split("\n");
              this.hotWordsList = this.hotWordsList.map((v, i) => {
                v.cnTitle = list[i];
                return v;
              });
            });
          }
        })
        .catch((res) => {
          this.showLoading = false;
          console.log(res);
        });
    },
    changeCate() {
      this.$nextTick(() => {
        this.getHotWords();
      });
    },
    getProductList() {
      let level = "";
      let list = this.list.map((v) => {
        level = v.level;
        return v.id;
      });
      if (level == "adset") {
        findSaleByAdSetId({ adsetId: list.join() }).then((res) => {
          if (res.code == 0 && res.data.length) {
            this.productList = res.data;
          }
        });
      } else {
        findSaleByCampaignIds({ ids: list.join() }).then((res) => {
          if (res.code == 0 && res.data.length) {
            this.productList = res.data;
          }
        });
      }
    },
  },
  mounted() {
    this.getMonths();
    if (this.list && this.list.length) {
      this.getProductList();
    }
  },
};
</script>
<style lang="scss">
.recommend-content {
  padding-left: 20px;
}
.recomment-dialog {
  h3 {
    font-size: 14px;
    color: #888;
    line-height: 1;
  }
  .el-main {
    padding: 10px;
    .flex-btw {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .el-dialog__body {
    padding: 5px;
  }
  .tags {
    flex: 1;
    text-align: left;
    display: flex;
    max-height: 180px;
    overflow-y: auto;
    div {
      flex: 1;
      .tag-item {
        margin-right: 10px;
        margin-top: 5px;
      }
    }
  }
  .el-pagination {
    text-align: right;
  }
}
</style>